import { useTranslation } from 'react-i18next'
import HeroImage from '../../components/Animated/HeroImage'
import { useEffect, useState } from 'react'
import { animated, useTransition } from '@react-spring/web'
import Button from '../../components/System/Button'
import { useNavigate } from 'react-router-dom'

const titles = [
  'fullstack',
  'mobile',
  'web',
  'backend',
  'ai',
  'software',
  'graphics',
  'vr-ar',
]

type HeroSectionProps = {
  homeRef: React.RefObject<HTMLDivElement>
  contactRef: React.RefObject<HTMLDivElement>
}

const HeroSection = (props: HeroSectionProps) => {
  const { t } = useTranslation('hero')
  const navigate = useNavigate()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % titles.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  const transitions = useTransition(titles[index], {
    from: { transform: 'translateY(50%)', opacity: 0 },
    enter: { transform: 'translateY(0%)', opacity: 1 },
    leave: { transform: 'translateY(-50%)', opacity: 0 },
    config: { duration: 500 },
  })

  return (
    <section
      ref={props.homeRef}
      className='sm:flex sm:flex-row sm:justify-around sm:items-start sm:gap-y-0 sm:pt-36 flex flex-col gap-y-36 gap-x-4 items-center justify-center pt-12 px-12'
    >
      <div className='sm:text-start text-center '>
        <p className='text-xl text-surface-light'>{t('greetingIntro')}</p>
        <div className='lg:text-7xl text-5xl font-clash font-extrabold'>
          <h1>{t('dev')}</h1>
          {transitions((style, item) => (
            <animated.h1
              style={style}
              className='absolute sm:left-auto sm:right-auto left-0 right-0'
            >
              {t(item)}
            </animated.h1>
          ))}
        </div>
        <h2 className='relative lg:top-20 max-w-3xl top-14 text-xl text-surface-light'>
          {t('presentation')}
        </h2>
        <Button
          label={t('sayHi')}
          onClick={() => props.contactRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          size='large'
          className='relative lg:top-28 top-24'
          animated
        />
      </div>
      <HeroImage />
    </section>
  )
}

export default HeroSection
