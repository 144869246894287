import { animated, config, useSpring } from '@react-spring/web'
import { useState } from 'react'

type AnimatedGradientProps = {
  children?: React.ReactNode
  duration?: number
  className?: string
}

const AnimatedGradient = (props: AnimatedGradientProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const animation = useSpring({
    boxShadow: isHovered ? '8px 8px 0px #282825' : '3px 3px 0px #282825',
    transform: isHovered ? 'translate(-5px, -5px)' : 'translate(0px, 0px)',
  })

  const { gradient } = useSpring({
    from: {
      gradient:
        'linear-gradient(35deg, rgba(239,139,194,1) 0%, rgba(255,205,130,1) 44%, rgba(193,169,241,1) 70%)',
    },
    to: {
      gradient:
        'linear-gradient(35deg, rgba(239,139,194,1) 0%, rgba(255,205,130,1) 24%, rgba(193,169,241,1) 80%)',
    },
    config: {
      duration: 4000,
      ...config.stiff,
    },
    loop: {
      reverse: true,
    },
  })

  return (
    <animated.div
      style={{ backgroundImage: gradient, ...animation }}
      className={`-z-10 ${props.className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.children}
    </animated.div>
  )
}

export default AnimatedGradient
