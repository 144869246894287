import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Home from '../pages/Home/Home'
import RootLayout from '../layouts/RootLayout'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about',
        element: <Home />,
      },
      {
        path: '/my-work',
        element: <Home />,
      },
      {
        path: '/contact',
        element: <Home />,
      },
    ],
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
