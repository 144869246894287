type Topics = {
  name: string
  color:
    | 'primary'
    | 'warning'
    | 'success'
    | 'success-light'
    | 'danger'
    | 'danger-light'
}

export type Project = {
  name: string
  descriptionKey: string
  images: string[]
  topics: Topics[]
  link?: string
}

export const projects: Project[] = [
  {
    name: 'Portfolio 2024',
    descriptionKey: 'portfolio2024Description',
    images: ['/assets/projects/portfolio/portfolio_cover.webp'],
    topics: [
      {
        name: 'Web',
        color: 'success-light',
      },
      {
        name: 'UX/UI',
        color: 'primary',
      },
    ],
    link: 'https://github.com/Sinan-Karakaya/Portfolio-2024'
  },
  {
    name: 'Localz',
    descriptionKey: 'localzDescription',
    images: ['/assets/projects/localz/localz_cover.webp'],
    topics: [
      {
        name: 'Web',
        color: 'success-light',
      },
      {
        name: 'Mobile',
        color: 'danger-light',
      },
      {
        name: 'Backend',
        color: 'warning',
      },
      {
        name: 'Tech4Good',
        color: 'primary',
      }
    ],
    link: 'TODO'
  },
  {
    name: 'RType',
    descriptionKey: 'rtypeDescription',
    images: ['/assets/projects/rtype/rtype_cover.webp'],
    topics: [
      {
        name: 'C++',
        color: 'warning',
      },
      {
        name: 'Game',
        color: 'danger-light',
      },
    ],
    link: 'https://github.com/Sinan-Karakaya/R-Type'
  },
]
