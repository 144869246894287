import { animated, AnimatedProps, useSpring } from '@react-spring/web'
import React, { useState } from 'react'

type InputProps = AnimatedProps<React.InputHTMLAttributes<HTMLInputElement>>

const Input = (props: InputProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const animation = useSpring({
    boxShadow: isHovered ? '8px 8px 0px #282825' : '3px 3px 0px #282825',
    transform: isHovered ? 'translate(-5px, -5px)' : 'translate(0px, 0px)',
  })

  return (
    <animated.input
      {...props}
      style={animation}
      className={`p-3 rounded-xl border-base border-surface bg-white ${props.className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  )
}

export default Input
