import { useTranslation } from 'react-i18next'
import Input from '../../components/System/Input'
import TextArea from '../../components/System/TextArea'
import Button from '../../components/System/Button'
import { useForm } from '@formspree/react'
import AnimatedHover from '../../components/Animated/AnimatedHover'

type ContactSectionProps = {
  contactRef: React.RefObject<HTMLDivElement>
}

const ContactSection = (props: ContactSectionProps) => {
  const { t } = useTranslation('contactHome')
  const [state, handleSubmit] = useForm('mdknkwdv')

  return (
    <section ref={props.contactRef} className='scroll-mt-24 sm:scroll-mt-0 grid sm:grid-cols-2 grid-cols-1 sm:gap-x-12 py-12 sm:px-20 px-6'>
      <div>
        <h2 className='text-4xl font-clash font-semibold sm:pb-12 pb-4'>
          {t('title')}
        </h2>
        <p
          className='text-surface-light sm:pb-0 pb-8'
          dangerouslySetInnerHTML={{ __html: t('body') }}
        />
      </div>

      <div>
        <form className='grid gap-4' onSubmit={handleSubmit}>
          <Input
            className='w-full'
            placeholder={t('namePlaceholder')}
            name='Name'
            required
          />
          <div className='grid sm:grid-cols-2 grid-cols-1 sm:gap-x-4 sm:gap-y-0 gap-y-4'>
            <Input
              className='w-full'
              placeholder={t('emailPlaceholder')}
              type='email'
              name='Email'
              required
            />
            <Input
              className='w-full'
              placeholder={t('phonePlaceholder')}
              type='tel'
              name='Phone'
              required
            />
          </div>
          <TextArea
            placeholder={t('messagePlaceholder')}
            name='Message'
            required
          />
          <div className='grid sm:grid-cols-2'>
            <Button
              label={
                state.submitting
                  ? t('submitting')
                  : state.errors
                  ? t('error')
                  : state.succeeded
                  ? t('success')
                  : t('submit')
              }
              color={
                state.succeeded
                  ? 'success-light'
                  : state.errors
                  ? 'danger-light'
                  : state.submitting
                  ? 'warning'
                  : 'primary'
              }
              size='large'
              onClick={() => {}}
              type='submit'
              animated
              className='sm:col-start-2'
            />
          </div>
        </form>
      </div>
      <div className='sm:pt-0 pt-12'>
        <p className='font-medium text-surface-light'>{t('emailAt')}</p>
        <AnimatedHover>
          <a
            href='mailto:karakaya.sinan@proton.me'
            className='text-lg font-medium'
          >
            karakaya.sinan@proton.me
          </a>
        </AnimatedHover>
        <br />
        <AnimatedHover>
          <a href='tel:+33781984783' className='text-lg font-medium'>
            +33 7 81 98 47 83
          </a>
        </AnimatedHover>
      </div>
    </section>
  )
}

export default ContactSection
