import { useTranslation } from 'react-i18next'
import { Project } from '../data/projects'
import Button from './System/Button'
import { useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import AnimatedHover from './Animated/AnimatedHover'

type ProjectHeroProps = {
  project: Project
  imageOnLeft: boolean
}

const ProjectHero = (props: ProjectHeroProps) => {
  const { t } = useTranslation('projectsHome')
  const [hovered, setHovered] = useState(false)

  const imageHover = useSpring({
    transform: hovered ? 'scale(1.02)' : 'scale(1)',
    filter: hovered ? 'brightness(0.85)' : 'brightness(1)',
    config: { tension: 300, friction: 20 },
  })

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className='sm:grid sm:grid-cols-2 hidden'>
        {props.imageOnLeft && (
          <animated.img
            src={props.project.images[0]}
            alt={props.project.name}
            className='w-full h-full object-cover'
            style={imageHover}
          />
        )}
        <div className='sm:p-16 p-8'>
          <div className='flex gap-x-2 pb-8'>
            {props.project.topics.map((topic, index) => (
              <Button
                label={topic.name}
                onClick={() => {}}
                size='small'
                rounded
                animated
                color={topic.color}
                className='font-medium'
                key={index}
              />
            ))}
          </div>
          <AnimatedHover isHovered={hovered}>
            <h4 className='w-full text-3xl font-clash font-semibold'>
              {props.project.name}
            </h4>
          </AnimatedHover>
          <p
            className='pt-2'
            dangerouslySetInnerHTML={{
              __html: t(props.project.descriptionKey),
            }}
          />
          {/* <AnimatedHover className='pt-10' isHovered={hovered}>
            <p className='text-xl font-medium'>{t('seeMore')} →</p>
          </AnimatedHover> */}
        </div>
        {!props.imageOnLeft && (
          <animated.img
            src={props.project.images[0]}
            alt={props.project.name}
            className='w-full h-full object-cover'
            style={imageHover}
          />
        )}
      </div>

      {/* FOR MOBILE LAYOUT */}
      <div className='sm:hidden'>
        <animated.img
          src={props.project.images[0]}
          alt={props.project.name}
          className='w-full h-full object-cover'
          style={imageHover}
        />
        <div className='sm:p-16 p-8'>
          <div className='flex gap-x-2 pb-8'>
            {props.project.topics.map((topic, index) => (
              <Button
                label={topic.name}
                onClick={() => {}}
                size='small'
                rounded
                animated
                color={topic.color}
                className='font-medium'
                key={index}
              />
            ))}
          </div>
          <h4 className='w-full pb-2 text-3xl font-clash font-semibold'>
            {props.project.name}
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: t(props.project.descriptionKey),
            }}
          />
          {/* <p className='text-xl font-medium pt-10'>{t('seeMore')} →</p> */}
        </div>
      </div>
    </div>
  )
}

export default ProjectHero
