import { useState } from 'react'
import { animated, useSpring } from '@react-spring/web'

type CardProps = {
  children: React.ReactNode
  className?: string
}

const Card = (props: CardProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const animation = useSpring({
    boxShadow: isHovered ? '8px 8px 0px #282825' : '3px 3px 0px #282825',
    transform: isHovered ? 'translate(-5px, -5px)' : 'translate(0px, 0px)',
  })

  return (
    <animated.div
      style={animation}
      className={`${props.className} p-6 bg-white rounded-xl border-base border-surface shadow-medium w-fit`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.children}
    </animated.div>
  )
}

export default Card
