import AnimatedGradient from './Animated/AnimatedGradient'

const Footer = () => {
  return (
    <footer className='border-t-base border-surface sm:flex items-center justify-around p-8'>
      <div className='flex justify-between items-center'>
        <div className='flex gap-4 items-center'>
          <AnimatedGradient className='sm:w-12 sm:h-12 w-6 h-6 rounded-lg border-base border-surface' />
          <h1 className='sm:block hidden font-clash font-bold text-4xl'>
            Sinan Karakaya
          </h1>
          <h1 className='sm:hidden font-clash font-bold text-4xl'>
            Sinan K.
          </h1>
        </div>
      </div>
      <div className='-light text-lg sm:pt-0 pt-8'>
        © 2024 Sinan Karakaya <br />
        Design inspired from&nbsp;
        <a href='https://kristi.digital/' className='underline'>
          Kristi.digital
        </a>
      </div>
    </footer>
  )
}

export default Footer
