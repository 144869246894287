import ContactSection from './Contact'
import HeroSection from './Hero'
import ProjectSection from './Projects'
import SkillSection from './Skills'
import TimelineSection from './Timeline'
import { useRefs } from '../../layouts/RootLayout'

const Home = () => {
  const refs = useRefs()

  return (
    <div>
      <HeroSection homeRef={refs.homeRef} contactRef={refs.contactRef} />
      <TimelineSection />
      <SkillSection aboutRef={refs.aboutRef} />
      <ProjectSection workRef={refs.workRef} />
      <ContactSection contactRef={refs.contactRef} />
    </div>
  )
}

export default Home
