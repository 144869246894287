import { useState } from 'react'
import { routes } from '../data/routes'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from '@react-spring/web'
import AnimatedHover from './Animated/AnimatedHover'
import AnimatedGradient from './Animated/AnimatedGradient'

type NavbarProps = {
  homeRef: React.RefObject<HTMLDivElement>
  aboutRef: React.RefObject<HTMLDivElement>
  workRef: React.RefObject<HTMLDivElement>
  contactRef: React.RefObject<HTMLDivElement>
}

const Navbar = (props: NavbarProps) => {
  const { t } = useTranslation('navbar')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const menuAnimation = useSpring({
    transform: isMenuOpen ? 'translateX(0)' : 'translateX(-100%)',
    opacity: isMenuOpen ? 1 : 0,
  })

  const handleNav = (route: string) => {
    switch (route) {
      case 'home':
        window.scrollTo({ top: 0, behavior: 'smooth' })
        break
      case 'about':
        props.aboutRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case 'myWork':
        props.workRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case 'contact':
        props.contactRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      default:
        window.scrollTo({ top: 0, behavior: 'smooth' })
        break
    }

    setIsMenuOpen(false)
  }

  return (
    <div className='fixed bg-background w-full z-50 border-b-base border-surface'>
      <div className='flex justify-between items-center p-6'>
        <button onClick={() => handleNav('home')}>
          <div className='flex gap-4 items-center'>
            <AnimatedGradient className='sm:w-20 sm:h-20 w-12 h-12 rounded-lg border-base border-surface' />
            <h1 className='sm:block hidden font-clash font-bold text-4xl'>
              Sinan Karakaya
            </h1>
            <h1 className='sm:hidden font-clash font-bold text-4xl'>
              Sinan K.
            </h1>
          </div>
        </button>

        <nav className='lg:flex hidden gap-4 font-clash-light font-medium'>
          {routes.map((route) => (
            <AnimatedHover key={route.path}>
              <button onClick={() => handleNav(route.nameKey)} className='text-lg'>
                {t(route.nameKey)}
              </button>
            </AnimatedHover>
          ))}
        </nav>

        {/* MOBILE FORMAT */}

        <div className='lg:hidden block'>
          <button onClick={() => setIsMenuOpen(true)}>
            <img
              src='/assets/hamburger.svg'
              className='w-10 h-10'
              alt='hamburger menu icon'
            />
          </button>
        </div>

        <animated.div
          className='fixed top-0 left-0 bg-background w-screen h-screen z-50'
          style={menuAnimation}
        >
          <div className='w-full flex justify-end'>
            <button onClick={() => setIsMenuOpen(false)}>
              <img
                src='/assets/cross.svg'
                className='w-10 h-10'
                alt='close menu icon'
              />
            </button>
          </div>

          <div className='w-full h-full flex justify-center items-center'>
            <nav className='flex flex-col gap-6 font-clash text-surface-light font-medium underline text-center pb-24'>
              {routes.map((route) => (
                <button key={route.path} onClick={() => handleNav(route.nameKey)} className='text-2xl'>
                  {t(route.nameKey)}
                </button>
              ))}
            </nav>
          </div>
        </animated.div>
      </div>
    </div>
  )
}

export default Navbar
