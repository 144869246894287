import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Router from './components/Router'

import './i18n'
import Loader from './components/Loader'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <div className='font-work text-surface'>
        <Router />
      </div>
    </Suspense>
  </React.StrictMode>
)
