export const routes = [
    {
        path: '/',
        nameKey: 'home',
    },
    {
        path: '/about',
        nameKey: 'about',
    },
    {
        path : '/my-work',
        nameKey: 'myWork',
    },
    {
        path : '/contact',
        nameKey: 'contact',
    }
]