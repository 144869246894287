import { Outlet, useOutletContext } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useRef } from 'react'

type ContextType = {
  homeRef: React.RefObject<HTMLDivElement>
  aboutRef: React.RefObject<HTMLDivElement>
  workRef: React.RefObject<HTMLDivElement>
  contactRef: React.RefObject<HTMLDivElement>
}

const RootLayout = () => {
  const refs = {
    homeRef: useRef<HTMLDivElement>(null),
    aboutRef: useRef<HTMLDivElement>(null),
    workRef: useRef<HTMLDivElement>(null),
    contactRef: useRef<HTMLDivElement>(null),
  }

  return (
    <div>
      <Navbar
        homeRef={refs.homeRef}
        aboutRef={refs.aboutRef}
        workRef={refs.workRef}
        contactRef={refs.contactRef}
      />
      <div className='h-24' />
      <Outlet context={refs} />
      <Footer />
    </div>
  )
}

export function useRefs() {
  return useOutletContext<ContextType>()
}

export default RootLayout
