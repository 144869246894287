import AnimatedGradient from "./Animated/AnimatedGradient"

const Loader = () => {
  return (
    <div className='flex w-full h-full justify-center items-center'>
      <AnimatedGradient />
    </div>
  )
}

export default Loader
