import { useTranslation } from 'react-i18next'
import { timeline } from '../../data/timeline'

const TimelineSection = () => {
  const { t } = useTranslation('timeline')

  return (
    <section className='bg-white mt-24 w-full flex-col justify-center sm:px-24 px-12 py-8'>
      <ol className='relative border-s-hard border-surface'>
        {timeline.map((milestone, index) => (
          <li className='mb-10 ms-4' key={index}>
            <div className='absolute  bg-surface w-3 h-3 rounded-sm mt-1.5 -start-1.5' />
            <div className='pl-4'>
              <time className='text-2xl font-clash font-semibold'>
                {milestone.icon}&nbsp;&nbsp;{milestone.year}
              </time>
              <p className='text-surface-light'>{t(milestone.description)}</p>
            </div>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default TimelineSection
