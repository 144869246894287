import { useTranslation } from 'react-i18next'
import Card from '../../components/System/Card'

const cards = [
  {
    title: 'productTitle',
    description: 'productDescription',
  },
  {
    title: 'expertiseTitle',
    description: 'expertiseDescription',
  },
  {
    title: 'creativityTitle',
    description: 'creativityDescription',
  },
]

type SkillSectionProps = {
  aboutRef: React.RefObject<HTMLDivElement>
}

// TODO: add icons to cards
const SkillSection = (props: SkillSectionProps) => {
  const { t } = useTranslation('skills')

  return (
    <section ref={props.aboutRef} className='scroll-mt-24 sm:scroll-mt-32 py-12 sm:px-20 px-6'>
      <h2 className='w-full text-center sm:text-5xl text-4xl font-clash font-semibold'>
        {t('title')}
      </h2>
      <div className='flex lg:flex-row flex-col lg:gap-x-12 gap-x-0 lg:gap-y-0 gap-y-4 justify-around lg:items-start items-center pt-12'>
        {cards.map((card, index) => (
          <Card key={index}>
            <h3 className='text-3xl font-clash font-medium'>{t(card.title)}</h3>
            <p
              className='text-surface-light pt-4'
              dangerouslySetInnerHTML={{ __html: t(card.description) }}
            />
          </Card>
        ))}
      </div>
    </section>
  )
}

export default SkillSection
