type Milestone = {
    year: number;
    description: string;
    icon: string
}

export const timeline: Milestone[] = [
    {
        year: 2016,
        description: 'start',
        icon: '🎉'
    },
    {
        year: 2021,
        description: 'epitech',
        icon: '👨‍💻'
    },
    {
        year: 2022,
        description: 'ers1',
        icon: '🌱'
    },
    {
        year: 2023,
        description: 'ers2',
        icon: '🚀',
    },
    {
        year: 2024,
        description: 'ovh',
        icon: '☁️'
    },
    {
        year: 2024,
        description: 'korea',
        icon: '🇰🇷'
    }
]