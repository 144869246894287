import AnimatedGradient from './AnimatedGradient'

const HeroImage = () => {
  return (
    <div className='relative'>
      <img
        sizes='(max-width: 889px) 100vw, 889px'
        srcSet='
          /assets/me/me_n26n8z_c_scale,w_200.webp 200w,
          /assets/me/me_n26n8z_c_scale,w_437.webp 437w,
          /assets/me/me_n26n8z_c_scale,w_608.webp 608w,
          /assets/me/me_n26n8z_c_scale,w_723.webp 723w,
          /assets/me/me_n26n8z_c_scale,w_798.webp 798w,
          /assets/me/me_n26n8z_c_scale,w_825.webp 825w,
          /assets/me/me_n26n8z_c_scale,w_876.webp 876w,
          /assets/me/me_n26n8z_c_scale,w_889.webp 889w'
        src='/assets/me/me_n26n8z_c_scale,w_889.webp'
        className='z-10 w-96 h-96 absolute bottom-0 left-0 object-contain'
        alt=''
        style={{ pointerEvents: 'none' }}
      />
      <AnimatedGradient className='w-80 h-80 rounded-2xl border-hard border-surface shadow-hard' />
    </div>
  )
}

export default HeroImage
