import { animated, useSpring } from '@react-spring/web'
import { useState } from 'react'

type ButtonsProps = {
  onClick: () => void
  className?: string
  label: string
  image?: string
  size: 'small' | 'medium' | 'large'
  animated?: boolean
  rounded?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?:
    | 'primary'
    | 'warning'
    | 'success'
    | 'success-light'
    | 'danger'
    | 'danger-light'
}

const Button = (props: ButtonsProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const animation = useSpring({
    boxShadow: isHovered ? '8px 8px 0px #282825' : '3px 3px 0px #282825',
    transform: isHovered ? 'translate(-5px, -5px)' : 'translate(0px, 0px)',
  })

  const getSizeClasses = (size: 'small' | 'medium' | 'large') => {
    switch (size) {
      case 'small':
        return 'px-3 py-[0.35rem] text-sm'
      case 'medium':
        return 'px-4 py-2 text-lg'
      case 'large':
        return 'px-6 py-3 text-xl'
      default:
        return ''
    }
  }

  const getColorClass = (color: string | undefined) => {
    switch (color) {
      case 'primary':
        return 'bg-primary'
      case 'warning':
        return 'bg-warning'
      case 'success':
        return 'bg-success'
      case 'success-light':
        return 'bg-success-light'
      case 'danger':
        return 'bg-danger'
      case 'danger-light':
        return 'bg-danger-light'
      default:
        return 'bg-primary'
    }
  }

  return (
    <animated.button
      className={`
      text-surface font-bold border-base border-surface shadow-medium
      ${props.className ? props.className : ''}
      ${props.rounded ? 'rounded-full' : 'rounded-lg'}
      ${getColorClass(props.color)}
      ${props.image ? 'flex items-center gap-x-2' : ''}
      ${getSizeClasses(props.size)}
    `}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={props.onClick}
      style={props.animated ? animation : {}}
      type={props.type ? props.type : 'button'}
    >
      {props.image && (
        <img
          src={props.image}
          alt=''
          className={`${props.size === 'small' ? 'w-4 h-4' : 'w-6 h-6'}`}
        />
      )}
      {props.label}
    </animated.button>
  )
}

export default Button
