import { animated, AnimatedProps, useSpring } from '@react-spring/web'
import { useState } from 'react'

type TextAreaProps = AnimatedProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>
>

const TextArea = (props: TextAreaProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const animation = useSpring({
    boxShadow: isHovered ? '8px 8px 0px #282825' : '3px 3px 0px #282825',
    transform: isHovered ? 'translate(-5px, -5px)' : 'translate(0px, 0px)',
  })

  return (
    <animated.textarea
      {...props}
      className={`p-3 rounded-xl border-base border-surface bg-white ${props.className}`}
      style={animation}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      rows={7}
    />
  )
}

export default TextArea
