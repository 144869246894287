import { useEffect, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'

type AnimatedHoverProps = {
  children: React.ReactNode
  duration?: number
  color?: string
  className?: string
  isHovered?: boolean
}

const AnimatedHover = (props: AnimatedHoverProps) => {
  const [isHovered, setIsHovered] = useState(props.isHovered || false)

  const underlineAnimation = useSpring({
    transform: isHovered ? 'scaleX(1)' : 'scaleX(0)',
    backgroundColor: props.color || '#d86bd2',
    config: {
      duration: props.duration || 200,
    },
  })

  useEffect(() => {
    if (props.isHovered !== undefined) {
      setIsHovered(props.isHovered)
    }
  }, [props.isHovered])

  return (
    <div
      className={`inline-block relative ${props.className}`}
      onMouseEnter={props.isHovered ? () => {} : () => setIsHovered(true)}
      onMouseLeave={props.isHovered ? () => {} : () => setIsHovered(false)}
    >
      {props.children}
      <animated.div
        style={{
          ...underlineAnimation,
          position: 'absolute',
          bottom: 0,
          transformOrigin: 'center',
          height: '2px',
          width: '100%',
        }}
      />
    </div>
  )
}

export default AnimatedHover
