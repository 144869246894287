import { useTranslation } from 'react-i18next'
import { projects } from '../../data/projects'
import ProjectHero from '../../components/ProjectHero'

type ProjectSectionProps = {
  workRef: React.RefObject<HTMLDivElement>
}

const ProjectSection = (props: ProjectSectionProps) => {
  const { t } = useTranslation('projectsHome')
  return (
    <section ref={props.workRef} className='scroll-mt-24 sm:scroll-mt-32'>
      <h2 className='py-12 sm:px-20 px-6 w-full text-center text-4xl font-clash font-semibold'>
        {t('title')}
      </h2>
      <div className='bg-white'>
        {projects.map((project, index) => (
          <div key={`a ${index}`}>
            <ProjectHero
              project={project}
              imageOnLeft={index % 2 === 0}
              key={index}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default ProjectSection
